import Component from '../../../assets/scripts/modules/component'
import onScrollChange from '../../../assets/scripts/utilities/on-scroll-change'

export default class MenuBarComponent extends Component {
  init() {
    this.endorseMenuOpen = false
    this.buttonMenu = this.element.querySelector('.button--menu')
    this.logoBar = this.element.querySelector('.menu-bar__logo')
    this.reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

    if (!this.buttonMenu) {
      return
    }

    this.buttonMenu.addEventListener('click', () => window.dispatchEvent(new CustomEvent('open-menu-overlay')))

    if (document.body.classList.contains('subsite--321')) {
      this.initQOSMLogoScroll()
    }

    if (document.body.classList.contains('subsite--nmoq')) {
      this.initNMOQLogoScroll()
    }

    if (document.body.classList.contains('subsite--mathaf')) {
      window.addEventListener('gsap-loaded', () => this.initMathafHomeAnimation())
    }

    this.hideNonNativeFromScreenreaders()

    this.venueButtons = [...this.element.querySelectorAll('.button--venues')]
    this.venueButtons.forEach(button =>
      button.addEventListener('click', event => {
        event.preventDefault()
        window.dispatchEvent(new CustomEvent('open-network-map'))
      })
    )
  }

  hideNonNativeFromScreenreaders() {
    const documentLanguage = document.documentElement.getAttribute('lang')

    if (!documentLanguage) {
      return
    }

    const items = [...this.element.querySelectorAll(`.menu-bar__link-text[lang]:not([lang='${documentLanguage}'])`)]
    items.forEach(item => item.setAttribute('aria-hidden', true))
  }

  initQOSMLogoScroll() {
    const logo = this.element.querySelector('.logo__321-wrapper')
    const logoText = this.element.querySelector('.logo__321-text')
    const logoWidth = logoText.clientWidth
    const barHeight = getComputedStyle(document.querySelector('body')).getPropertyValue('--menu-bar-height')
    const logoBarHeight = parseFloat(barHeight.substring(0, barHeight.length - 2))
    const topWrapHeight = document.querySelector('body.subsite--321 .menu-bar .menu-bar__top-wrapper')?.clientHeight

    // Once per session -RVP
    if (sessionStorage.getItem('qosm-logo')) {
      logoText.style.opacity = 0
      logo.style.left = 0
      this.logoBar.style.height = `${logoBarHeight}px`

      if (document.documentElement.getAttribute('lang') === 'en') logoText.style.display = 'none'

      return
    }

    this.logoBar.style.height = `${logoBarHeight + topWrapHeight}px`

    const handler = () => {
      if (!logoText) {
        return
      }

      // Stop animation once it has been completed -RVP
      if (sessionStorage.getItem('qosm-logo')) {
        return
      }

      const scrollY = window.scrollY
      const translateText = (logoWidth * scrollY) / topWrapHeight

      if (scrollY <= 50) {
        if (document.documentElement.getAttribute('lang') === 'en') {
          logo.style.left = `-${translateText}px`
        }

        logoText.style.opacity = 1 - (2 * scrollY) / topWrapHeight
        logoText.style.display = 'block'
        this.logoBar.style.height = `${logoBarHeight + topWrapHeight - scrollY}px`
      } else {
        logoText.style.opacity = 0
        if (document.documentElement.getAttribute('lang') === 'en') logoText.style.display = 'none'
        logo.style.left = 0
        this.logoBar.style.height = `${logoBarHeight}px`
        sessionStorage.setItem('qosm-logo', true)
      }
    }

    handler()
    onScrollChange(handler)
  }

  initNMOQLogoScroll() {
    const barHeight = getComputedStyle(document.querySelector('body')).getPropertyValue('--menu-bar-height')
    const logoBarHeight = parseFloat(barHeight.substring(0, barHeight.length - 2))
    const logoIcon = this.element.querySelector('[data-name="nmoq-logo-icon"]')
    const logoText = this.element.querySelector('[data-name="nmoq-logo-text"]')

    const hasExhibitionBackBtn = document.querySelector('.exhibition-header .button--back')
    const menubarIsTransparent = this.element.classList.contains('menu-bar--transparent')

    // Once per session -RVP
    if (sessionStorage.getItem('nmoq-logo')) {
      logoText.style.display = 'none'
      logoText.style.opacity = 0
      this.logoBar.style.height = `${logoBarHeight}px`
      return
    }

    this.logoBar.style.height = `${logoBarHeight + 50}px`

    const handler = () => {
      if (!logoText || !logoIcon) {
        return
      }

      // Stop animation once it has been completed -RVP
      if (sessionStorage.getItem('nmoq-logo')) {
        return
      }

      const scrollY = window.scrollY

      if (scrollY <= 50) {
        logoText.style.opacity = 1 - (3 * scrollY) / 50
        logoText.style.display = 'block'
        logoText.style.height = `${100 - 2 * scrollY}%`
        logoIcon.style.flex = `0 0 ${70 + (30 * scrollY) / 50}%`
        this.logoBar.style.height = `${logoBarHeight + 50 - scrollY}px`
        document.querySelector('#main').style.transform = `translateY(${-logoBarHeight}px)`
        if (hasExhibitionBackBtn && menubarIsTransparent) {
          hasExhibitionBackBtn.style.transform = `translateY(${logoBarHeight}px)`
        }
      } else {
        document.querySelector('#main').style.transition = 'transform var(--timing-extraslow) var(--timing-slow)'
        document.querySelector('#main').style.transform = `translateY(${0}px)`
        logoText.style.display = 'none'
        logoText.style.opacity = 0
        this.logoBar.style.height = `${logoBarHeight}px`
        if (hasExhibitionBackBtn && menubarIsTransparent) {
          hasExhibitionBackBtn.style.transition = 'transform calc(var(--timing-extraslow) * 2) var(--timing-slow)'
          hasExhibitionBackBtn.style.transform = `translateY(${0}px)`
        }
        sessionStorage.setItem('nmoq-logo', true)
      }
    }

    handler()
    onScrollChange(handler)
  }

  initMathafHomeAnimation() {
    if (!this.element.classList.contains('menu-bar--homepage') || this.reduceMotion) return

    const logo = this.element.querySelector('.logo')
    const logoText = this.element.querySelector(document.documentElement.getAttribute('lang') === 'en' ? '.mathaf-logo-en__text' : '.mathaf-logo-ar__text')
    const metadata = document.querySelector('.highlight-header__metadata-wrapper')
    const header = document.querySelector('.highlight-header')

    setTimeout(() => {
      header.classList.toggle('gradient-active', true)
    }, 3000)

    window.GSAP.fromTo(logoText, { opacity: 1 }, { opacity: 0, duration: 2, delay: 1.5, ease: 'power3.out' })
    window.GSAP.to(logo, { width: 280, height: 56, duration: 1, delay: 2.75, ease: 'power3.out' })
    window.GSAP.fromTo(metadata, { opacity: 0 }, { opacity: 1, duration: 3, delay: 3, ease: 'power3.out' })
  }

  initFirestationHomeAnimation() {
    if (!this.element.classList.contains('menu-bar--homepage') || this.reduceMotion) return

    const logo = this.element.querySelector('.logo')
    window.GSAP.to(logo, { width: 233, duration: 2.5, delay: 0.5, ease: 'power3.out' })
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.menu-bar').forEach(element => {
    element.instance = element.instance || new MenuBarComponent(element)
  })
)
